<template>
  <div class="row">
    <div class="col-lg-7 m-auto">
      <h1>{{ t('verify_email') }}</h1>
        <div v-if="success">
          <div class="alert alert-success" role="alert">
            {{ success }}
          </div>
          <router-link :to="{ name: 'login' }" class="btn btn-primary">
            {{ t('login') }}
          </router-link>
        </div>
        <div v-else>
          <div class="alert alert-danger" role="alert">
            {{ error || t('failed_to_verify_email') }}
          </div>

          <router-link :to="{ name: 'verification.resend' }" class="small float-end">
            {{ t('resend_verification_link') }}
          </router-link>
        </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { useMeta } from 'vue-meta'
import { ref } from '@vue/runtime-core'
const qs = (params) => Object.keys(params).map(key => `${key}=${params[key]}`).join('&')
export default {
  async beforeRouteEnter (to, from, next) {
    try {
      const { data } = await axios.post(`${process.env.VUE_APP_API_URL}/email/verify/${to.params.id}?${qs(to.query)}`)
      next(vm => { vm.success = data.success })
    } catch (e) {
      next(vm => { vm.error = e.response.data.status })
    }
  },
  setup () {
    const { t } = useI18n()
    useMeta({
      title: t('verify_email')
    })
    const error = ref('')
    const success = ref('')
    return {
      t,
      error,
      success,
      breadcrumbs: [
        { name: t('home'), href: '/' },
        { name: t('verify_email'), href: null, active: true }
      ]
    }
  }
}
</script>
